import { NgModule } from '@angular/core';
import { Routes,RouterModule} from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule,routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HomeComponent } from './home/home.component';
// import { RobotComponent } from './robot/robot.component';
// import { ScadaComponent } from './scada/scada.component';
// import { VisionComponent } from './vision/vision.component';
// import { MesComponent } from './mes/mes.component';
// import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [];

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    // HomeComponent,
    // RobotComponent,
    // ScadaComponent,
    // VisionComponent,
    // MesComponent,
    // NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
