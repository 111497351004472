import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InspectionComponent } from './inspection/inspection.component';
import { MesComponent } from './mes/mes.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { RobotComponent } from './robot/robot.component';
import { ScadaComponent } from './scada/scada.component';
import { VisionComponent } from './vision/vision.component';

const routes: Routes = [
  { path : '', component: HomeComponent},
  { path : 'inspection', component: InspectionComponent},
  { path : 'robot', component: RobotComponent},
  { path : 'scada', component: ScadaComponent},
  { path : 'vision', component: VisionComponent},
  { path : 'mes', component: MesComponent},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [InspectionComponent,HomeComponent,RobotComponent,ScadaComponent,VisionComponent,MesComponent,NotfoundComponent]