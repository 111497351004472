import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfound',
  template: `
    <div class = "container card" style = "margin-top:50px;height:800px;text-align:center;padding-top:350px;">
      <h1>404 Not Found Content</h1>
    </div>
  `,
  styles: [
  ]
})
export class NotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
