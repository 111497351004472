import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scada',
  template: `
    <div class = "row container-content-page">
    <div class = "col-6 content-middle">
        <h4>SCADA & DCS</h4><br>
        <p>
            The supervisory control and data acquisition) is a type of
            industrial control systems (Industrial Control System or ICS) is controlled by a computer system to monitor and control industrial processes that exist.    
        </p>
       <br>
        <ul>
            <li>Cloud + Web Station + Database</li>
            <li>OPC OPCUA Server Application</li>
            <li>Stand Alone, RTU or PLC</li>
            <li>TCP/IP, wifi, or Any Bus</li>
            <li>5G Devices, Androids & IOS</li>
        </ul>        
    </div>
    <div class = "col-6 content-middle">
        <img class = "lower_image" src = "assets/image/scada/header.webp">
    </div>
    <div  style = "margin-top:100px;">
        <h4>DCS</h4>
        <p>
            The functionality of SCADA and DCS systems are very similar, but DCS tends to be used on large continuous process
            plants where high reliability and security is important as the autonomous controllers are distributed throughout the system
        </p>
    </div>
    <div align = "center">
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/scada/image1.webp" >
        </div>
        <div class = "image_area_robot">    
            <img class = "part_image_robot" src = "assets/image/scada/image2.webp" >
        </div>
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/scada/image3.webp" >
        </div>
    </div>
</div>
  `,
  styles: [
  ]
})
export class ScadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
