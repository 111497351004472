<div class = "menu-bar row">
  <div class = "col-2">
      <img src = "assets/image/logo.png" width="200px" style="margin:10px;"  >
  </div>
  <div class = "col list_btn" align = "right">
      <a routerLink="/">
        <div class = "btn-header" > Home </div>
      </a>
      <a routerLink="/" (click)="scroll('services')">
          <div class = "btn-header"> Service </div>
      </a>
      <a (click)="scroll('contact_us')" >
          <div class = "btn-header"> Contact Us </div>
      </a>
  </div>
</div>
<div *ngIf="showLoadingIndicator">Loading...</div>
<router-outlet></router-outlet>
<footer id = "contact_us">
  <div class = "row container-content">
      <div class = "col-4 box-border-gray">
          <h3> Contact Us </h3>
          <br>
          72/4 Moo 4,Klong Phra Udom, Pakkret, Nonthaburi<br>
           Thailand 
          <br>
          <br>+66 92 447-4989
          <br>+66 91 716-1964
          <br>
          <br>
          sale@palica.co.th
          <br>support@palica.co.th
      </div>
      <div class = "col-4 box-border">
          <h3>  Contact Form </h3>
          <br>
          <div class = "row">
              <div class = "col-4">
                  <input id = "name_text" class = "form-control" placeholder="Your Name">
              </div>
              <div class = "col-4">
                  <input id = "phone_text" class = "form-control" placeholder="Phone">
              </div>
              <div class = "col-4">
                  <input id = "email_text" class = "form-control" placeholder="Email">
              </div>
          </div>
      
          <br>
          <div>
              <textarea class = "form-control" placeholder="Your Message" rows="10" id = "test_text"></textarea>
          </div>
          <br>
          <button class = "btn btn-primary" onclick="SendEmail()">
              Send Message
          </button>

      </div>
      <div class = "col-4 box-border">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.435908211488!2d100.48024391534805!3d13.932631096829818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e285b28d4a4065%3A0x654654dd5ec44b37!2sPhalika%20Company%20Limited!5e0!3m2!1sth!2sth!4v1612170597209!5m2!1sth!2sth" class = "gmap" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
     </div>
  </div>
  <!-- Mobile -->
</footer>
<div class = "chat-contacts" hidden>
  <div id = "chat-box" class = "chat-box">
      <!-- <div class = "chat_header">&nbsp;</div> -->
      <div id ="area-chat" class = "text-area-chat">
          <div class = "chat-balloon-left">Hello can i help you?</div>
      </div>
      <div class = "chat-input-panel row">
          <div class = "col-8 input-chat"x>
              <input id = "message" onkeypress = "sendMessageEnter(event)" class="form-control">
          </div>
          <div class = "col-4">
              <button class = "btn btn-dark" onclick = "SendMessage()">Send</button>
          </div>
      </div>
  </div>
  <div class = "icon-chat" onclick = "OpenChat()">
      <i class="far fa-comments"></i>
  </div>
</div>