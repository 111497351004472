import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  template: `
    <img class = "image_header" src = "assets/image/image_port.webp"  width="100%" height="600px">
    <div id = "services" ></div>
    <div class = "container-content">
        <div class = "content-margin">
            <h3>Our Services</h3>
            <hr class = "hr-bold" width = "13%" align = "left">
            
        </div>
        <div align = "center" style = "margin-top: 50px;">
            <div class = "image_area_part" routerLink="/robot">
                <div id="imageDiv">
                    <img class = "part_image" id="image_part" src="assets/image/part1.webp" />
                </div>
                <div class = "name-tag-part">
                    Robot
                </div>
            </div>
            <div class = "image_area_part" routerLink="/scada">
                <div id="imageDiv">
                    <img class = "part_image" id="image_part" src="assets/image/part2.webp" />
                </div>
                <div class = "name-tag-part">
                    SCADA & DCS
                </div>
            </div>
            <div class = "image_area_part" routerLink="/vision">
                <div id="imageDiv">
                    <img class = "part_image" id="image_part" src="assets/image/part3.webp" />
                </div>
                <div class = "name-tag-part">
                    Vision
                </div>
            </div>
            <div class = "image_area_part">
                <div id="imageDiv">
                    <img class = "part_image" id="image_part" src="assets/image/part4.webp" />
                </div>
                <div class = "name-tag-part">
                Software
                </div>
            </div>
        </div>
        <hr  width = "100%">
        <div class = "content-bottom row" style="margin:0;">
            <div class = "box-white col-6 content-middle">
                <br>
                <h3 style="text-transform: uppercase;">Inspection Machine</h3>
                <hr class = "hr-bold" width = "40%" align = "left">
                  <ul>
                      <li>Eddy Current <span>Crack & Hardness Inspection</span></li>
                      <li>Ultrasonic</li>
                      <li>Leakage Detection</li>
                      <li>Vision System</li>
                  </ul>

                <div class = "btn-header margin-top-30" align = "center" routerLink="/inspection">
                    Read More
                </div>
            </div>
            
            
            <div class = "col-6 content-middle" align = "right" >
                <img class = "lower_image" src = "assets/image/lower_content1.webp">
            </div>
        </div>
        <div style = "margin-top:100px;">
            <hr>
        </div>
        <div class = "content-bottom row" style="margin:0;">
            <div class = "col-6 content-middle" align = "right" >
                <img class = "lower_image" src = "assets/image/lower_content2.webp">
            </div>
            <div class = "box-white-right col-6 content-middle">
                <br>
                <h3 style="text-transform: uppercase;">MES, DMC, Traceability, and AI</h3>
                <hr class = "hr-bold" width = "60%" align = "left">
                <p>
                    As a moderate ERP is not yet fully integrated the manufacturing, 
                    we supply the ERP module that integrated to our MES system. Together 
                    with our DMC and Traceability, You can access all data transparently. 
                    With AI ML and DL for big data, your system can be sufficiently smart 
                    to inform and executed beforehand. 

                </p>
                <div class = "btn-header margin-top-30" align = "center" routerLink="/mes">
                    Read More
                </div>
            </div>

        </div>
    </div>
  `,
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
