import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robot',
  template: `
      <div class = "row container-content-page">
      
      <div class = "col-6 content-middle">
          <h4>Robot</h4><br>
          <p>
              Integrating the best robotic solutions for your business. 
              From robots of SCARA, 6-axis or More with Special Tooling and Gantry System, proven reputation for precision and reliability at the leading edge of industrial robot design. Our systems are recognized for their speed, accuracy, and ease of use. With Smart Motion Control Technology; an engineering advancement for higher precision and efficiency. Manufacturing companies can now achieve lower production cost, better quality, and higher overall productivity. 
          </p>
      </div>
      <div class = "col-6 content-middle">
          <img class = "lower_image" src = "assets/image/robot/header.webp">
      </div>
      <div style = "margin-top:100px;" align = "center">
          <div class = "image_area_robot">
              <img class = "part_image_robot" src = "assets/image/robot/image1.webp" >
              <div class = "name-tag-part-page">
                  Epson C8 for Interior parts milling 
              </div>
          </div>
          <div class = "image_area_robot">
              <img class = "part_image_robot"  src = "assets/image/robot/image2.webp">
              <div class = "name-tag-part-page">
                  Auto Silicone Sealant Applicator
              </div>
          </div>
          <div class = "image_area_robot">
              <img class = "part_image_robot" src = "assets/image/robot/image3.webp" >
              <div class = "name-tag-part-page">
                  Custom Paint Robot
              </div>
          </div>
      </div>
  </div>
  `,
  styles: [
  ]
})
export class RobotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
