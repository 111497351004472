import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mes',
  template: `
    <div class = "row container-content-page">
    <div class = "col-6 content-middle">
        <h4>MES, DMC, Traceability, and AI</h4><br>
        <p>
            Automotive plastic extruders are also using the benefit of our MES especially in parts count, mold life monitoring / stock / model check. Torques, Forces, temperature, and pressure and other process values are real-time monitored and evaluated. And providing information for better production quality, less defects and down-time.
        </p>        
    </div>
    <div class = "col-6 content-middle">
        <img class = "lower_image" src = "assets/image/mes/header.webp">
    </div>
    <div style = "margin-top:100px;">
        SAIC MOTOR CP (MG) is currently enjoying our MES system for cockpit assembly line, as MG3 was developed by other company and have many problems on the quality.
    </div>
    <div align = "center">
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/mes/image1.webp" >
        </div>
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/mes/image2.webp" >
        </div>
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/mes/image3.webp" >
        </div>
    </div>
</div>
  `,
  styles: [
  ]
})
export class MesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
