import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspection',
  template: `
            <div class = "row container-content-page">
            <div class = "col-6 content-middle">
                <h4>Inspection Machine</h4><br>
                Design and Build
                <ul>
                    <li>
                        Eddy Current Test Crack & Hardness Inspection 
                    </li>
                    <li>
                        Magnetic Particle Test 
                    </li>
                    <li>
                        Fluorescent Penetration Test
                    </li>
                    <li>
                        Ultrasonic Test Crack Detection
                    </li>
                    <li>
                        Leakage Detection
                    </li>
                    <li>
                        Pokayoke
                    </li>
                </ul>     
            </div>
            <div class = "col-6 content-middle">
                <img class = "lower_image" src = "assets/image/inspec/header.webp">
            </div>
            <div style = "margin-top:100px;">
                
            </div>
            <div align = "center">
                <div class = "image_area_robot">
                    <img class = "part_image_robot" src = "assets/image/inspec/image1.webp" >
                </div>
                <div class = "image_area_robot">
                    <img class = "part_image_robot" src = "assets/image/inspec/image2.webp" >
                </div>
                <div class = "image_area_robot">
                    <img class = "part_image_robot" src = "assets/image/inspec/image3.webp" >
                </div>
            </div>
        </div>
  `,
  styles: [
  ]
})
export class InspectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
