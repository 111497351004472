import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision',
  template: `
    <div class = "row container-content-page">
    <div class = "col-6 content-middle">
        <h4>Machine Vision + AI + Deep Learning</h4><br>
        <p>
            Why spend on expensive devices when our system can provide you the best price-to-performance. Two 20 MagaPixel for analysis and classify the  textile surface, threads, and Armrest Fin Switch Screening quality. As the total combination of 100 models, our vision Deep Learning System can perform the correction less than 1 second and cost less than 200k THB.
        </p>        
    </div>
    <div class = "col-6 content-middle">
        <img class = "lower_image" src = "assets/image/machine/header.webp">
    </div>
    <div style = "margin-top:100px;">
        High Speed Inspection, general of OK/NG of bottle cap, Film, and Packaging are widely using our system
    </div>
    <div align = "center">
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/machine/image1.webp" >
        </div>
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/machine/image2.webp" >
        </div>
        <div class = "image_area_robot">
            <img class = "part_image_robot" src = "assets/image/machine/image3.webp" >
        </div>
    </div>
</div>
  `,
  styles: [
  ]
})
export class VisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
